import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { environment } from '../environments/environment';

const uri = environment.graphqlUrl; // <-- add the URL of the GraphQL server here
// export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  // return {
    // link: httpLink.create({ uri }),
    // cache: new InMemoryCache(),
  // };
// }
export function createApollo(httpLink: HttpLink): any {
  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  return {
    link: httpLink.create({uri}),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  };
}
@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
